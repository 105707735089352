import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then( m => m.GalleryPageModule)},
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)},
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then( m => m.BlogPageModule)},
  { path: 'story', loadChildren: () => import('./story/story.module').then( m => m.StoryPageModule)},
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
